export default {
  attemptToReconnect: 'A tentar reconectar...',
  betFor: 'para',
  bettingTimeLeft: 'Tempo de apostas restante',
  coldField: 'Campo frio',
  connected: 'Conectado',
  connecting: 'Conectando',
  disconnected: 'Desconectado',
  eighthFinals: 'Oitavos de final',
  finalist: 'Finalista',
  finals: 'Finais',
  gameDeactivated: 'Jogo desativado!',
  headToHead: 'Cara a cara',
  hotField: 'Campo quente',
  knockoutStage: 'Etapa de Knockout',
  latestRaces: 'Últimas corridas',
  loading: 'A carregar',
  outrights: 'Outrights',
  problemInCommunication: 'Há problemas de comunicação com o servidor!',
  problemWithGame: 'Há um problema com o jogo, por favor aguarde!',
  productName: 'Virtual Drag Races',
  qualifiesTo: 'Fica qualificado como',
  quarterFinalist: 'Quadrifinalista',
  quarterFinals: 'Quartos de final',
  raceNo: 'Corrida n.º',
  raceNow: 'agora',
  racerOut: 'out',
  raceSchedule: 'Programação de corrida',
  raceTime: 'Tempo de corrida',
  raceWinner: 'Vencedor de corrida',
  reconnected: 'Reconectado',
  results: 'Resultados',
  round: 'Rodada',
  roundIn: 'em',
  roundNumber: 'Número da rodada',
  roundStartsIn: 'Rodada {{roundNumber}} começa em',
  semiFinalist: 'Semi-finalista',
  semiFinals: 'Semi-finais',
  statistics: 'Estatísticas',
  tournament: 'Torneio',
  tournamentNo: 'Torneio n.º',
  tournamentOutrights: 'Outrights de torneio',
  tournamentWinner: 'Vencedor do Torneio',
  upcoming: 'Próximo',
  waitingForTheRace: 'A aguardar a corrida',
  winner: 'Vencedor',
  winnerShort: 'v',
};
